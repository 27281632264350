'use client';

import { routes } from '@/routes';
import { AppContext } from '@/wrappers/AppContext';
import { Button } from '@wolfejs/ui/Button/Button';
import { Image } from '@wolfejs/ui/Image';
import { useUIStore } from '@wolfejs/ui/stores/UIStore';
import { UIColors, UIShapes, UISizes, UIVariants } from '@wolfejs/ui/types/UI';
import cn from 'classnames';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import type { MouseEventHandler } from 'react';
import React, { useContext } from 'react';

type AnonHeaderProps = {
  onSignInClick?: MouseEventHandler<HTMLButtonElement>;
  onSignUpClick?: MouseEventHandler<HTMLButtonElement>;
  onNotificationClick?: MouseEventHandler<HTMLButtonElement>;
  onCartClick?: MouseEventHandler<HTMLButtonElement>;
  onProfileClick?: () => void;
  onSignOutClick?: MouseEventHandler<HTMLButtonElement>;
  isLoggedIn?: boolean;
  homeButton?: React.ReactNode;
  hideButtons?: boolean;
};

export const AnonHeader = ({ onSignInClick, hideButtons = false }: AnonHeaderProps) => {
  const context = useContext(AppContext);
  const { portalSlug: slug } = useParams();

  // TODO: Theme Mode should not be retrieved here since the header should be rendered on SSR to prevent FOUC
  const themeMode = useUIStore(state => state.themeMode);
  const brandLogo = (
    <Image
      className="h-[32px]"
      src={
        themeMode === 'dark' && context.portal?.logoReverse
          ? context.portal?.logoReverse ?? '/wolfe/logo-reverse.svg'
          : context.portal?.logo ?? '/wolfe/logo.svg'
      }
      alt={context.portal?.name}
    />
  );

  return (
    <div className={cn('w-full bg-white dark:bg-default-900')}>
      <div className={cn('container relative flex justify-between p-4')}>
        <div className={cn('flex justify-start')}>
          <Link href={`/${slug}${routes.home}`}>{brandLogo}</Link>
        </div>

        <div className={cn('flex items-center gap-4')}>
          {/* <div>
                <ThemeModeToggleContainer />
              </div> */}
          {!hideButtons && !context.portal?.hideLoginHeaderButtons ? (
            <>
              <div id="btn-sign-in" className="group">
                <Link className="text-black dark:text-white" href={`/${slug}${routes.signin}`}>
                  <Button
                    color={UIColors.default}
                    variant={UIVariants.ghost}
                    shape={UIShapes.rounded}
                    size={UISizes.sm}
                    onClick={onSignInClick}
                  >
                    Log in
                  </Button>
                </Link>
              </div>
              <div id="btn-sign-up" className="group">
                <Link href={`/${slug}${routes.signup}`}>
                  <Button
                    color={UIColors.primary}
                    shape={UIShapes.rounded}
                    size={UISizes.sm}
                    variant={UIVariants.solid}
                  >
                    Get started
                  </Button>
                </Link>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
